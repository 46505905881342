import { success, error, abort } from "redux-saga-requests";

export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const GET_JOB_TYPE_SEASONAL = 'GET_JOB_TYPE_SEASONAL';
export const UPDATE_USER="UPDATE_USER";
export const VERIFY_MEMBER="VERIFY_MEMBER";
export const VERIFY_MEMBER_SEASONAL="VERIFY_MEMBER_SEASONAL";
export const VERIFY_PAY="VERIFY_PAY";
export const SUBMIT_FORM_TEMPOTERY = "SUBMIT_FORM_TEMPOTERY";
export const SUBMIT_FORM_SEASONAL = "SUBMIT_FORM_SEASONAL";
export const CHECK_TRANSACTION_ABA="CHECK_TRANSACTION_ABA";
export const WING_GENERATE_HASH="WING_GENERATE_HASH";
export const ACLEDA_PAYMENT="ACLEDA_PAYMENT";


export const get_type_job_f = (payload) => ({
  type: GET_JOB_TYPE,
  payload
});
export const get_type_job_seasonal_f = (payload) => ({
  type: GET_JOB_TYPE_SEASONAL,
  payload
});
export const verify_member_f = (payload) =>({
  type: VERIFY_MEMBER,
  payload
});
export const verify_member_seasonal_f = (payload) =>({
  type: VERIFY_MEMBER_SEASONAL,
  payload
});
export const payment_verify_f = (payload) =>({
  type: VERIFY_PAY,
  payload
});
export const submit_form_tempotery_f = payload => ({
  type: SUBMIT_FORM_TEMPOTERY,
  payload,
});
export const submit_form_seasonal_f = payload => ({
  type: SUBMIT_FORM_SEASONAL,
  payload,
});

export const check_transaction_aba_f = payload => ({
  type: CHECK_TRANSACTION_ABA,
  payload,
});

export const wing_generate_hash_f = payload => ({
  type: WING_GENERATE_HASH,
  payload,
});


export const acleda_payment_f = payload => ({
  type: ACLEDA_PAYMENT,
  payload,
});

const initialState = {
  loading: false,
  error: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * GET_JOB_TYPE
     */
    case GET_JOB_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_JOB_TYPE):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_JOB_TYPE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_JOB_TYPE):
      return { ...state, loading: false };
      /**
     * GET_JOB_TYPE_SEASONAL
     */
    case GET_JOB_TYPE_SEASONAL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_JOB_TYPE_SEASONAL):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_JOB_TYPE_SEASONAL):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_JOB_TYPE_SEASONAL):
      return { ...state, loading: false };
     /**
     * UPDATE_USER
     */
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(UPDATE_USER):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_USER):
      return { ...state, loading: false };
      /**
       * VERIFY_MEMBER
       */
      case VERIFY_MEMBER:
          return {
          ...state,
          loading: true,
          error: false,
          };
      case success(VERIFY_MEMBER):     
          return {
          ...state,
          ...action.payload,
          loading: false
          };
      case error(VERIFY_MEMBER):
          return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
          };
      case abort(VERIFY_MEMBER):
          return { ...state, loading: false };
              /**
       * VERIFY_MEMBER_SEASONAL
       */
      case VERIFY_MEMBER_SEASONAL:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(VERIFY_MEMBER_SEASONAL):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(VERIFY_MEMBER_SEASONAL):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(VERIFY_MEMBER_SEASONAL):
        return { ...state, loading: false };
      /**
       * VERIFY_PAY
       */
      case VERIFY_PAY:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(VERIFY_PAY):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(VERIFY_PAY):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(VERIFY_PAY):
        return { ...state, loading: false };
        /**
       * SUBMIT_FORM_TEMPOTERY
       */
       case SUBMIT_FORM_TEMPOTERY:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(SUBMIT_FORM_TEMPOTERY):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(SUBMIT_FORM_TEMPOTERY):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(SUBMIT_FORM_TEMPOTERY):
        return { ...state, loading: false };
        /**
       * SUBMIT_FORM_SEASONAL
       */
       case SUBMIT_FORM_SEASONAL:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(SUBMIT_FORM_SEASONAL):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(SUBMIT_FORM_SEASONAL):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(SUBMIT_FORM_SEASONAL):
        return { ...state, loading: false };
          /**
   
      /**
     * WING_GENERATE_HASH
     */
      case WING_GENERATE_HASH:
        return {
          ...state,
          wingHash:false,
          loading: true,
          error: false,
        };
      case success(WING_GENERATE_HASH):
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(WING_GENERATE_HASH):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true,
        };
      case abort(WING_GENERATE_HASH):
        return { ...state, loading: false };
    /**
     * ACLEDA PAYMENT
     */
      case ACLEDA_PAYMENT:
        return {
          ...state,
          acledaPayment:false,
          loading: true,
          error: false,
        };
      case success(ACLEDA_PAYMENT):
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(ACLEDA_PAYMENT):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true,
        };
      case abort(ACLEDA_PAYMENT):
        return { ...state, loading: false };


    default:
      return state;
  }

  
  
}

export default homeReducer;