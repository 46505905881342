/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { IMAGE } from '../assets/image';
import '../styles/ScreenLogin.css';
import Header from '../components/header'
import { COLOR, COLORMESSAGE } from '../assets/color';
import { Col, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import AlertMessage from '../components/alertMessage';

export default class ScreenLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePassword: false,
      typeLogin: "",
      phonenumbe: "",
      password: "",
      showMess: "",
      succee: false,
      error: false,
      url: '',
      youtube: {},
      redirect: {
        page: "",
        action: false
      },
      facebook: {
        facebookID: "",
        facebookName: ""
      },
      encropt: "",
      decrypt:""
    }
  }
  componentDidMount() {
    if (this.props.user.login && this.props.user.login.message === 'success') {
      this.setState({
        redirect: {
          page: "/form",
          action: true
        }
      })
    }
    this.setState({
      error: false
    })
  }
  componentWillReceiveProps(nextProps, nextState) {
    const { user } = this.props;
    try {
      if (nextProps.user.login && nextProps.user.login !== this.props.user.login) {
        if (nextProps.user.login.message === 'success') {
          this.setState({
            redirect: {
              page: "/form",
              action: true
            }
          })
        }
      }
      if (nextProps.user.errorLogin && nextProps.user.errorLogin !== user.errorLogin) {
        if (nextProps.user.errorLogin.message === "empty") {
          this.setState({
            error: true,
            showMess: "សូមអភ័យទោសគណនីមិនត្រឹមត្រូវ!",
          })
        }
      }
    } catch (e) {
    }
  }

  _clickLogin(phonenumbe, password) {
    var data = {
      "phone":phonenumbe,
      "pass":password,
      "type":"phone"
    }

    this.props.userLogin_f(data)
  }

  responseFacebook = (user) => {
    if (user.name && user.id) {
      var data = {
        "username": user.name,
        "password": "",
        "phone": user.id,
        "registerType": "facebook"
      }
      this.props.userSigup_f(data);
      this.setState({
        typeLogin: "facebook",
        facebook: {
          ...this.state.facebook,
          facebookID: user.id,
          facebookName: user.name,
        }
      })
    }
  }


  render() {
    const { redirect } = this.state;
    var { app } = this.props;
    return (
      <div >
        {redirect.action && redirect.page !== "" &&
          <Redirect to={redirect.page} />
        }
        {
          this.state.error ?
            <AlertMessage
              closeOnClick={
                setTimeout(() => { this.setState({ error: false }) }, 8000)
              }
              styleMessage={COLORMESSAGE.error}
              message={this.state.showMess}
            />
            : null
        }
        <Row className="_login_ fixed_nav" >
          {app && app.schedule_exam && app.schedule_exam.results && app.schedule_exam.results[0] && app.schedule_exam.results[0].registerStatus === 0 ?
            <Header
              notication={true}
            /> :
            <Header
              signup={
                <Link to="/signup">
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="បង្កើតគណនី"
                    onClick={this.props.signup} className=" _buttonNotification btn_create_acc_login">
                    <h1 className="create_new_acc">បង្កើតគណនី</h1>
                  </button>
                </Link>
              }
              notication={true}
            />

          }


        </Row>
        <div className="row">
          <Col xl={3} lg={3} md={2} sm={1} xs={0}>
          </Col>

          <Col xl={6} lg={6} md={8} sm={10} xs={12} className=" text-center log_form">
            <div className="div_logo_login" >
              <img alt='logo' src={IMAGE.logotwo} id="logo_image__" />
            </div>
            <div className="cont_login_padding">
              <input
                onChange={(value) => {
                  const re = /^[0-9\b]+$/;
                  if (value.target.value === '' || re.test(value.target.value)) {
                    var a = String(value.target.value)
                    if (value.target.value[0] !== '0' && value.target.value[0]) {
                      a = '0' + String(value.target.value)
                    }
                    this.setState({
                      phonenumbe: a,
                      message: false
                    })
                  }
                }}
                value={this.state.phonenumbe.toString()}
                placeholder="លេខទូរស័ព្ទ"
                className="input_login" />
              <input
                onChange={(value) => {
                  this.setState({
                    password: value.target.value
                  })
                }}
                value={this.state.password.toString()}
                type={!this.state.visiblePassword ? "password" : "text"}
                placeholder="លេខសម្ងាត់"
                className="input_login" />
              <div className="text-right ">
                <button
                  className="btnHideShow d-inline"
                  onClick={() => { this.setState({ visiblePassword: !this.state.visiblePassword }) }}>
                  <img alt='show password' src={this.state.visiblePassword === false ? IMAGE.openEye : IMAGE.closeEye} className="img_btnHideShow" />
                  មើលលេខសម្ងាត់
                </button>
              </div>
              <p>
                សូមរក្សាគណនី និងលេខសម្ងាត់របស់អ្នក
                <br />
                ដើម្បីតាមដានការចុះឈ្មោះ!
              </p>
              <button
                onClick={() => { this._clickLogin(this.state.phonenumbe, this.state.password) }}
                style={{ backgroundColor: COLOR.MAIN, color: COLOR.MAINLATTER }}
                className="btnLogin_">
                ចូលគណនី
              </button>
              {/* <div
                className="lin_login"
                style={{ backgroundColor: COLOR.MAIN }}></div> */}
            {/* {  <h6 className="loginwith text-center">ចូលគណនីជាមួយ</h6>
              <FacebookLogin
                appId="382708070436831"
                autoLoad={false}
                fields="name,address,birthday,gender,picture"
                scope={'public_profile'}
                cssClass="_bntFacebbok"
                textButton={''}
                icon={<img alt='facebook' src={IMAGE.facebook} className="image_bntFacebbok" style={{ backgroundColor: 'white' }} />}
                callback={this.responseFacebook}
              />} */}
              {
                this.state.youtube &&
                  this.state.youtube.title !== "" ?
                  <a
                    href={this.state.url}
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={this.state.youtube.title}
                    className="linkYoutube text-center">
                    <h1>{this.state.youtube.title}</h1>
                  </a>
                  : null}

            </div>
          </Col>
          <Col xl={3} lg={3} md={2} sm={1} xs={0}>

          </Col>
        </div>
      </div>
    );
  }
}
