import { success, error, abort } from "redux-saga-requests";

export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';

export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const USER_LOGOUT = "USER_LOGOUT";
export const RENEW_TOKEN_ADMIN = "RENEW_TOKEN_ADMIN";

export const userLogin_f = payload => ({
  type: LOGIN,
  payload
});
export const userSigup_f = payload => ({
  type: SIGNUP,
  payload
});

export const userLogin = payload => ({
  type: LOGIN_ADMIN,
  payload
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});


const initialState = {
  loading: false,
  error: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * LOGIN
     */
    case LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(LOGIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(LOGIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(LOGIN):
      return { ...state, loading: false };
    /**
     * SIGNUP
     */
    case SIGNUP:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SIGNUP):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SIGNUP):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SIGNUP):
      return { ...state, loading: false };


    /**
  * LOGIN_ADMIN
  */
    case LOGIN_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(LOGIN_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(LOGIN_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(LOGIN_ADMIN):
      return { ...state, loading: false };

    /**
  * USER_LOGOUT
  */
    case USER_LOGOUT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(USER_LOGOUT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(USER_LOGOUT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(USER_LOGOUT):
      return { ...state, loading: false };
    /**
      * RENEW_TOKEN_ADMIN
      */
    case RENEW_TOKEN_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(RENEW_TOKEN_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(RENEW_TOKEN_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(RENEW_TOKEN_ADMIN):
      return { ...state, loading: false };

    default:
      return state;
  }



}

export default userReducer;