/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { IMAGE } from "../assets/image";
import "../styles/ScreenSignup.css";
import Header from "../components/header";
import { COLOR, COLORMESSAGE } from "../assets/color";
import { Col, Row } from "react-bootstrap";
import { Link,Redirect } from "react-router-dom";
import AlertMessage from "../components/alertMessage";
import {trimEmptyString} from '../utils';
export default class ScreenSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      message: false,
      messageText: "",
      userName: "",
      password: "",
      phone: "",
      comfirmPassword: "",
      visiblePassword: false,
      youtube: {},
      url: "",
      redirect: {
        page: "",
        action: false
      },
    };
  }
  componentDidMount() {
    this.setState({
      message: false,
      messageText: "",
      loading:false
    });
    if (this.props.user.login && this.props.user.login.message === 'success') {
      this.setState({
        redirect: {
          page: "/form-swp",
          action: true
        }
      })
    }
  }
  componentWillReceiveProps(NextProps) {
    const { user, home } = this.props;
    if (NextProps.user.signup && NextProps.user.signup !== user.signup) {
      if (NextProps.user.signup.message === "success") {
        var dataLogin = {
          "phone":this.state.phone,
          "pass":this.state.password,
          "type":"phone"
        };
        this.props.userLogin_f(dataLogin);
        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
        this.setState({
          message: false,
        });
        
      } 
    }

    // ERROR
    if(NextProps.user.errorSignup && NextProps.user.errorSignup !== user.errorSignup){
      if (NextProps?.user?.errorSignup?.message === "data-duplicate") {
      this.setState({
        loading:false,
        message: true,
        messageText: "សូមអភ័យទោស! លេខទូរស័ព្ទរបស់អ្នកធ្លាប់បានបង្កើតរួចហើយ!",
      });
    }
    }
  }
  _signup() {
    const { userName, password, phone, comfirmPassword } = this.state;
    const trimPass=trimEmptyString(password);
    const trimConfirmPass=trimEmptyString(comfirmPassword);

    if (
      userName &&
      trimPass &&
      phone &&
      trimConfirmPass &&
      trimConfirmPass === trimPass
    ) {
      if (trimConfirmPass.length >= 6) {
        this.setState({
          loading:true
        })
        var data = {
          "phone":phone,
          "pass":trimPass,
          "type":"phone",
          "name":userName,
          "device":2
        };
        this.props.userSigup_f(data);
      } else {
        this.setState({
          message: true,
          messageText: "សូមបំពេញលេខសម្ងាត់ ៦ខ្ទង់ យ៉ាងតិច!",
        });
      }
    } else {
      this.setState({
        message: true,
        messageText: "សូមបំពេញព័ត៌មានអោយបានត្រឹមត្រូវ!",
      });
    }
  }
  render() {
    const { redirect,loading } = this.state;
    const { app } = this.props;
    return (
      <div>
        {redirect.action && redirect.page !== "" &&
          <Redirect to={redirect.page} />
        }
        {this.state.message ? (
          <AlertMessage
            closeOnClick={setTimeout(() => {
              this.setState({ message: false });
            }, 8000)}
            styleMessage={COLORMESSAGE.error}
            message={this.state.messageText}
          />
        ) : null}
        <Row className="fixed_nav" style={{ padding: "10px 0%" }}>
          <Header
            notication={true}
            signup={
              <Link to="/">
                <button
                  type="button"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="ចូលគណនី"
                  onClick={this.props.signup}
                  className=" _buttonNotification btn_create_acc_login"
                >
                  <h1 className="create_new_acc">ចូលគណនី</h1>
                </button>
              </Link>
            }
          />
        </Row>
        <div className="row _signup_">
          <Col xl={3} lg={3} md={2} sm={1} xs={0}></Col>
          <Col xl={6} lg={6} md={8} sm={10} xs={12} className=" log_form">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="logo"
                src={IMAGE.logotwo}
                id="logo_image_sign" />
            </div>

            <div className="cont_login_padding">
              <h1 id="maintitle_signup">
                {app &&
                  app.schedule_exam &&
                  app.schedule_exam.results &&
                  app.schedule_exam.results[0] &&
                  app.schedule_exam.results[0].title}
              </h1>
              <input
                onChange={value => {
                  const re = /^[a-zA-Z0-9@.\\ b]+$/;
                  if (
                    value.target.value === "" ||
                    re.test(value.target.value)
                  ) {
                    this.setState({
                      userName: value.target.value,
                      message: false,
                    });
                  }
                }}
                value={this.state.userName.toString()}
                placeholder="ឈ្មោះគណនី"
                className="input_signup"
              />
              <input
                onChange={value => {
                  const re = /^[0-9\b]+$/;
                  if (
                    value.target.value === "" ||
                    re.test(value.target.value)
                  ) {
                    var a = String(value.target.value);
                    if (value.target.value[0] !== "0" && value.target.value[0]) {
                      a = "0" + String(value.target.value);
                    }
                    this.setState({
                      phone: a,
                      message: false,
                    });
                  }
                }}
                value={this.state.phone.toString()}
                placeholder="លេខទូរស័ព្ទ"
                className="input_signup"
              />
              <input
                onChange={value => {
                  this.setState({
                    password: value.target.value,
                    message: false,
                  });
                }}
                value={this.state.password.toString()}
                placeholder="លេខសម្ងាត់"
                type={!this.state.visiblePassword ? "password" : "text"}
                className="input_signup"
              />
              <input
                onChange={value => {
                  this.setState({
                    comfirmPassword: value.target.value,
                    message: false,
                  });
                }}
                type={!this.state.visiblePassword ? "password" : "text"}
                value={this.state.comfirmPassword.toString()}
                placeholder="បញ្ជាក់លេខសម្ងាត់"
                className="input_signup"
              />
              <div className="text-right ">
                <button
                  className="btnHideShow_signUp d-inline"
                  onClick={() => {
                    this.setState({
                      visiblePassword: !this.state.visiblePassword,
                    });
                  }}
                >
                  <img
                    alt="view password"
                    src={
                      this.state.visiblePassword === false
                        ? IMAGE.openEye
                        : IMAGE.closeEye
                    }
                    className="img_btnHideShow"
                  />
                  មើលលេខសម្ងាត់
                </button>
              </div>
              {/* <button onClick={()=>{
                this.setState({
                  userName: "BIENSOTHEARITH",
                  password: "070594606",
                  phone: "070594606",
                  comfirmPassword: "070594606",
                })
              }} style={{color:'red'}}>
                AAAA
              </button> */}
              <button
                disabled={loading}
                onClick={() => {
                  this._signup();
                }}
                style={{ backgroundColor: COLOR.MAIN, color: COLOR.MAINLATTER }}
                className="btnLogin_"
              >
                ស្នើសុំបង្កើតគណនី
              </button>
              {this.state.youtube && this.state.youtube.title !== "" ? (
                <a
                  href={this.state.url}
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={this.state.youtube.title}
                  className="linkYoutube text-center"
                >
                  <h1>{this.state.youtube.title}</h1>
                </a>
              ) : null}
            </div>
          </Col>
          <Col xl={3} lg={3} md={2} sm={3} xs={3}></Col>
        </div>
      </div>
    );
  }
}
