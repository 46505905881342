export const IMAGE = {
    logo: require('../assets/images/logo.png'),
    closeEye: require('./images/icon_eyeHide.png'),
    openEye: require('./images/icon_eyeOpen.png'),
    facebook: require('./images/facbooklogo.png'),
    notification: require('./images/icon_location.png'),
    notification_w: require('./images/icon_location_w.png'),
    wing: require('./images/wingBank.png'),
    aba: require('./images/ic_ABA_PAY_2x.png'),
    adduser: require('./images/user_add.png'),
    user: require('./images/user.png'),
    logotwo: require('./images/logotwo.png'),
    customer: require('./images/customer.png'),
    passport: require('./images/passport.jpg'),
    khqr: require('./images/khqr.png'),
    acleda:require('./images/acleda.png')

}
