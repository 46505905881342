import moment from 'moment';

export const timeExpireToken = (isToken,expiredFromServer) => {
  if (isToken !== null && expiredFromServer !== null) {
    const currentTime = moment().utcOffset("+7:00");
    const expireDate =moment.unix(expiredFromServer);
    if (moment(currentTime).isAfter(expireDate)) {
      return true;
    }
    return false;
  }
  return true;
};
