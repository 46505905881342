import React from 'react';
import '../styles/components/message.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default class AlertMessage extends React.Component{
    constructor() {
      super();
      this.state = {
       
      } 
       
    }
    componentDidMount(){
       toast(
            this.props.message, 
            {
            onClose:this.props.closeOnClick,
            style:this.props.styleMessage,
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progressStyle:{ background:`linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)`},
            closeButton:false
            }
            );
    }
    render() {
      return (
        <ToastContainer style={{wordWrap:' break-word'}} draggable={false}/>
      )
    }
}

export function AlertMessage_(tiext,onclose,style,yes){
  if(tiext !=="" && yes===true){
    toast(
      tiext, 
      {
      onClose:onclose,
      style:style,
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progressStyle:{ background:`linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)`},
      closeButton:false
      }
      );
  }
  return (
    <ToastContainer style={{wordWrap:' break-word'}} draggable={false}/>
  )
}