import React from 'react';
import '../styles/ScreenYoutube.css';
import Header from '../components/header'
import { Col, Row } from 'react-bootstrap';
export default class ScreenYoutube extends React.Component {
    render() {
        let { id } = this.props.match.params;
        const url = "https://www.youtube.com/embed/" + id;
        return (
            <div className="background_youtube">
                <Row className="_youble_ fixed_nav" >
                    <Header />
                </Row>
                <div className="row ">
                    <Col xl={3} lg={3} md={2} sm={1} xs={0}>
                    </Col>
                    <Col xl={6} lg={6} md={8} sm={10} xs={12} className=" text-center log_form">
                        <iframe
                            title='វិដេអូ'
                            className="iframe_video"
                            src={url}
                        >
                        </iframe>
                    </Col>
                    <Col xl={3} lg={3} md={2} sm={1} xs={0}>

                    </Col>
                </div>

            </div>
        );
    }
}
