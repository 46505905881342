import { success, error, abort } from "redux-saga-requests";

export const GET_NAME = 'GET_NAME'
export const APP_REFRESH = 'APP_REFRESH'
export const APP_WILL_REFRESH = 'APP_WILL_REFRESH';
export const APP_SCHEDULE = 'APP_SCHEDULE';
export const APP_SCHEDULE_SEASONAL = 'APP_SCHEDULE_SEASONAL';
export const APP_INFO = 'APP_INFO';

export const getName = payload => ({
  type: GET_NAME,
  payload
});

export const appRefresh = () => ({
  type: APP_REFRESH
});

export const appWillRefresh = () => ({
  type: APP_WILL_REFRESH
});
export const appSchedule_f = () => ({
  type: APP_SCHEDULE
});
export const appInfo_f = () => ({
  type: APP_INFO
});
export const appScheduleSeasonal_f = () => ({
  type: APP_SCHEDULE_SEASONAL
});


const initialState = {
  loading: false,
  error: false,
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
      /**
     * GET_NAME
     */
    case GET_NAME:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_NAME): 
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case error(GET_NAME):
      return {
        ...state,
        loading: false,
      };
    case abort(GET_NAME):
      return { ...state, loading: false };
      /**
     * APP_SCHEDULE
     */
    case APP_SCHEDULE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(APP_SCHEDULE): 
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case error(APP_SCHEDULE):
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case abort(APP_SCHEDULE):
      return { ...state, loading: false };
  /**
     * APP_SCHEDULE_SEASONAL
     */
  case APP_SCHEDULE_SEASONAL:
    return {
      ...state,
      loading: true,
      error: false,
    };
  case success(APP_SCHEDULE_SEASONAL): 
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  case error(APP_SCHEDULE_SEASONAL):
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  case abort(APP_SCHEDULE_SEASONAL):
    return { ...state, loading: false };


     /**
     * APP_INFO
     */
    case APP_INFO:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(APP_INFO): 
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case error(APP_INFO):
      return {
        ...state,
        loading: false,
      };
    case abort(APP_INFO):
      return { ...state, loading: false };
    /**
     * DEFAULT CASE
     */
    default:
      return state;
  }
}

export default registerReducer;