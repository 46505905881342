import { connect } from "react-redux";
import ScreenResult from "../screen/ScreenResult"
import {profile_f,} from '../modules/profile/reducer';
import {userLogout} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  profile_f,
  userLogout
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenResult);
