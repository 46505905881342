export const COLOR={
    MAIN:'#5489f5',
    MAINLATTER:'#FFFFFF'
    // 5489f5
}
export const COLORMESSAGE={
    succees:{
        display:'block',
        wordWrap:'break-word',
        backgroundColor:'#81c784',
        color:'white'
    },
    error:{
        display:'block',
        wordWrap:'break-word',
        backgroundColor:'#ef5350',
        color:'white'
    }
}
