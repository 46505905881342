import React, { Component } from 'react'
import {Col} from 'react-bootstrap';
import { IMAGE } from '../assets/image';
import '../styles/noschadule.css' 
import RefreshIcon from '@material-ui/icons/Refresh';

export default class NotFountPage extends Component {
    componentDidMount(){
        localStorage.removeItem('persist:@local10010')
    }
    _logout(){
        localStorage.removeItem('persist:@local10010');
        setTimeout(()=>{window.location.replace('/')},2000)
      }
    render() {
        var isPageNotShowLogin=this.props.isPageNotShowLogin?this.props.isPageNotShowLogin:false;
        return (
            <div >
              <div className="row">
                <Col xl={3} lg={3} md={2} sm={1} xs={0}>
                </Col>
                
                <Col  xl={6} lg={6} md={8} sm={10} xs={12} className=" text-center log_form">
                    <div className="div_logo_schandule" >
                        <img alt='logo' src={IMAGE.logotwo} id="logo_image_div_logo_schandule" />
                    </div>
                    <div className="cont_login_padding_noSchadule">
                        <h4>សូមអភ័យទោស </h4>
                        <h5> ​សូមអភ័យទោស ដំណើរនៅលើវេបសាយកំពុងជួបបញ្ហា​​នឹងត្រូវបានផ្អាកជាបណ្ដោះអាសន្ន</h5>
                        {
                            isPageNotShowLogin?
                            <button className="btnReload" onClick={()=>{this._logout()}}>
                                <RefreshIcon/> ត្រឡប់ទៅកាន់ទំព័រដើម
                            </button>:
                             <button className="btnReload" onClick={()=>{window.location.reload()}}>
                                <RefreshIcon/> ពិនិត្យឡើងវិញ
                            </button>
                        }
                       
                    </div>
                </Col>
                <Col xl={3} lg={3} md={2} sm={1} xs={0}>
              
                </Col>
             </div>
            
          </div>
        )
    }
}
