import React from 'react';
import "../styles/components/headers.css"
import '../styles/components/oneNotification.css'
export default class OneNotification extends React.Component {
  render() {
    return (
      <div className="row one_not">
        <h4 className="_id_notif">​១១ វិច្ឆិកា ២០២១ |  ១៦:១៥:១០</h4>
      </div>
    );
  }
}
