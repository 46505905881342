import { connect } from "react-redux";
import ScreenForm from "../screen/ScreenForm"
import {
      get_type_job_f,
      verify_member_f,
      payment_verify_f,
      submit_form_tempotery_f
      ,wing_generate_hash_f,
      acleda_payment_f
    } from '../modules/home/reducer'
import {profile_f} from '../modules/profile/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  get_type_job_f,
  verify_member_f,
  payment_verify_f,
  profile_f,
  submit_form_tempotery_f,
  wing_generate_hash_f,
  acleda_payment_f
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenForm);
